import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { enResources } from '@/utils/i18n/resources'

// eslint-disable-next-line import-x/no-named-as-default-member
i18n.use(initReactI18next).init({
  resources: {
    en: enResources,
  },
  fallbackLng: 'en',
  lng: 'en',
  supportedLngs: ['en'],
  defaultNS: 'fitify-admin',
  fallbackNS: Object.keys(enResources),
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  load: 'all',
  react: {
    transKeepBasicHtmlNodesFor: ['br', 'b', 'strong', 'i'],
    transSupportBasicHtmlNodes: true,
    useSuspense: false,
  },
  debug: false,
  detection: {
    order: ['header', 'path'],
  },
})

export default i18n
