import { colors } from 'fitify-ui'
import styled from 'styled-components'

import { ButtonStyles } from '../Button/Button.Styles'

export const StyledErrorFallback = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const StyledErrorFallbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 680px;
  text-align: center;
`

export const StyledErrorMessage = styled.div`
  margin: 16px 0;
`

export const StyledErrorDelimiter = styled.hr`
  width: 80px;
  margin-top: 16px;
  border: 1px solid ${colors.Gray300};
`

export const StyledErrorButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  ${ButtonStyles}:not(:first-child) {
    margin-left: 8px;
  }
`
