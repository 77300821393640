import { SerializableTimestamp } from '../types/common'

export const isSerializableTimestamp = (
  obj: any
): obj is SerializableTimestamp => {
  return Boolean(
    obj &&
      typeof obj._seconds === 'number' &&
      typeof obj._nanoseconds === 'number'
  )
}

export const isNumber = (value: any): value is number =>
  typeof value === 'number'
