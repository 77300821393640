import { type AxiosResponse } from 'axios'
import { WithId } from 'fitify-types/src/types/common'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'

import { API_URL, apiClient } from '@/api'

const getSessionsParams = (
  from?: HumanCoaching.DayRange['from'],
  to?: HumanCoaching.DayRange['to'],
  humanCoaching = true
): string => {
  const params = new URLSearchParams()

  if (from) {
    params.append('from', from)
  }
  if (to) {
    params.append('to', to)
  }

  if (humanCoaching) {
    params.append('human_coaching', '1')
  }

  return params.toString()
}

export const SessionService = {
  getSessions: async (
    id: string | string[],
    from?: HumanCoaching.DayRange['from'],
    to?: HumanCoaching.DayRange['to'],
    humanCoaching = true
  ): Promise<WithId<HumanCoaching.Session>[]> => {
    const params = getSessionsParams(from, to, humanCoaching)
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${id}/sessions${params ? `?${params}` : ''}`
    )

    return response.data.data
  },

  getSessionsAchievements: async (
    id: string | string[],
    from?: HumanCoaching.DayRange['from'],
    to?: HumanCoaching.DayRange['to'],
    humanCoaching = true
  ): Promise<Record<string, number>> => {
    const params = getSessionsParams(from, to, humanCoaching)
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${id}/sessions-achievements${
        params ? `?${params}` : ''
      }`
    )

    return response.data.data
  },

  getHeartRateRecords: async (
    userId: string,
    sessionId: string
  ): Promise<HumanCoaching.FitifyHeartRateSample[]> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/sessions/${sessionId}/heart-rate-records`
    )

    return response.data.data
  },
}
