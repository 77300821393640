import { TextStyles } from './Text.Styled'
import { TextTypes } from './Text.Types'

const HEADLINE_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']

const Text = ({
  children,
  as,
  html,
  variant = 'p',
  color = 'Black',
  fontFamily = 'Graphik LCG Web',
  fontWeight,
  ...rest
}: TextTypes.Props): JSX.Element => {
  const props = {
    as: as
      ? (as as keyof JSX.IntrinsicElements)
      : HEADLINE_TAGS.includes(variant)
        ? (variant as keyof JSX.IntrinsicElements)
        : ('p' as keyof JSX.IntrinsicElements),
    variant,
    color,
    fontWeight,
    fontFamily,
    ...rest,
  }

  return (
    <>
      {html && (
        <TextStyles dangerouslySetInnerHTML={{ __html: html }} {...props} />
      )}
      {children && <TextStyles {...props}>{children}</TextStyles>}
    </>
  )
}

export default Text
