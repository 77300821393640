import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { WithId } from 'fitify-types/src/types/common'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'

import { SessionService } from '@/api/services/SessionService'

import { AppDispatch, AppState } from '../store'

const sessionsAdapter = createEntityAdapter({
  selectId: (session: WithId<HumanCoaching.Session>) => session.id,
})

export type SessionState = ReturnType<
  typeof sessionsAdapter.getInitialState
> & {
  loading: boolean
  error: null | string
}

type FetchAllSessionParams = {
  userId: string
  from?: HumanCoaching.DayRange['from']
  to?: HumanCoaching.DayRange['to']
  humanCoaching: boolean
}

export const fetchAllSessions = createAsyncThunk<
  WithId<HumanCoaching.Session>[],
  FetchAllSessionParams,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('session/fetchAllSessions', async (data: FetchAllSessionParams) => {
  return SessionService.getSessions(
    data.userId,
    data.from,
    data.to,
    data.humanCoaching
  )
})

const initialState: SessionState = sessionsAdapter.getInitialState({
  loading: false,
  error: null,
})

const sessionSlice = createSlice({
  name: 'sessions',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSessions.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAllSessions.fulfilled, (state, action) => {
        state.loading = false
        sessionsAdapter.setAll(state, action.payload)
      })
      .addCase(fetchAllSessions.rejected, (state, action) => {
        state.loading = false
        state.error =
          action.error.message !== undefined ? action.error.message : null
        state.loading = false
      })
  },
})

export const {
  selectAll: selectAllSessions,
  selectById: selectSessionById,
  selectIds: selectSessionIds,
} = sessionsAdapter.getSelectors((state: AppState) => state.session)

export default sessionSlice.reducer
