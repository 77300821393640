import { addAlpha, colors, StyledIcon } from 'fitify-ui'
import styled from 'styled-components'

import { StyledText } from '@/components/core/Typography/Text.Styled'
import { Fitify } from '@/types/user'

export const TEXT_AND_ICON_SIZE = 14
const LINE_HEIGHT_PADDING = 2.8
const TEXT_WITH_LINE_HEIGHT = TEXT_AND_ICON_SIZE + 2 * LINE_HEIGHT_PADDING

export const StyledContainer = styled.div<{ $type: Fitify.ToastMessageType }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 13.2px 16px 16px;
  gap: 16px;
  border-radius: 4px;
  color: ${colors.White};
  min-width: 320px;

  ${({ $type }) => {
    switch ($type) {
      case 'error':
        return { backgroundColor: colors.Red600 }
      case 'success':
        return { backgroundColor: colors.Green600 }
      case 'info':
      default:
        return { backgroundColor: colors.Blue400 }
    }
  }};
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 ${TEXT_AND_ICON_SIZE}px;

  ${StyledIcon} {
    width: ${TEXT_AND_ICON_SIZE}px;
    height: ${TEXT_AND_ICON_SIZE}px;
    color: inherit;
  }
`

export const MessageContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  flex: 1;

  ${StyledText} {
    line-height: ${TEXT_WITH_LINE_HEIGHT}px;
  }
  ${IconWrapper} {
    height: ${TEXT_WITH_LINE_HEIGHT}px;
  }
`

export const MessageActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex: 0 0 auto;

  ${IconWrapper}, ${StyledText} {
    padding: ${LINE_HEIGHT_PADDING}px;
    line-height: 1;
    cursor: pointer;

    &:hover {
      background-color: ${addAlpha(colors.Black, 0.05)};
      border-radius: 4px;
    }
  }
`
