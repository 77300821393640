import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { UnitSystem, WithId } from 'fitify-types/src/types/common'
import { ExercisesDataCollection } from 'fitify-types/src/types/exercise-collection'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'
import undoable, { FilterFunction } from 'redux-undo'
import { v4 as uuidv4 } from 'uuid'

import { UserService } from '@/api/services/UserService'
import { AppDispatch, AppState } from '@/store/store'
import { CoachPlan } from '@/types/CoachPlan'
import {
  getEstimatedCalories,
  getSequenceActualDuration,
  getSequenceEstimatedDuration,
} from '@/utils/sequences'

import {
  getMaxSequenceRounds,
  recalculateSequenceExercisesRepsAndDuration,
  removeEmptyRounds,
  removeRoundFromSequenceExercises,
  updateSequencesCustomExercises,
} from './utils'

type IgnoreHistoryPayload<T> = {
  data: T
  ignoreHistory?: boolean
}

export interface ActivityDetail {
  day: WithId<HumanCoaching.Day>
  activity: WithId<HumanCoaching.Activity>
  session?: WithId<CoachPlan.Session>
  sequences: Record<string, CoachPlan.Sequence>
}

export const fetchActivityDetails = createAsyncThunk<
  {
    day: WithId<HumanCoaching.Day>
    sequences: Record<string, CoachPlan.Sequence>
    session?: WithId<CoachPlan.Session>
    activity: WithId<HumanCoaching.Activity>
  },
  { userId: string; date: string; activityId: string; sessionId?: string },
  {
    dispatch: AppDispatch
    state: AppState
  }
>(
  'activity/fetchActivity',
  async (data: {
    userId: string
    date: string
    activityId: string
    sessionId?: string
  }) => {
    return UserService.getActivityDetails(
      data.userId,
      data.date,
      data.activityId,
      data.sessionId
    )
  }
)

export const createActivity = createAsyncThunk(
  'activity/createActivity',
  async (data: {
    userId: string
    day: WithId<HumanCoaching.Day>
    activity: WithId<HumanCoaching.Activity>
  }) => {
    return UserService.createActivity(data.day.id, data.userId, data)
  }
)

export const updateActivity = createAsyncThunk(
  'activity/updateActivity',
  async (data: {
    userId: string
    day: WithId<HumanCoaching.Day>
    activity: WithId<HumanCoaching.Activity>
  }) => {
    return UserService.updateActivity(data.day.id, data.userId, data)
  }
)

export interface ActivityBuilderState {
  estimatedCalories: number
  estimatedDuration: number
  estimatedSequencesDuration: Record<
    string,
    { duration: number; actualDuration: number }
  >
  day: WithId<HumanCoaching.Day>
  activity: WithId<HumanCoaching.Activity>
  session: WithId<CoachPlan.Session>
  sequences: Record<string, CoachPlan.Sequence>
  containers: string[]
  loading: boolean
  isDirty: boolean
}

export const initialState: ActivityBuilderState = {
  estimatedCalories: 0,
  estimatedDuration: 0,
  estimatedSequencesDuration: {},
  day: {
    id: '',
    activities: [],
    completed: null,
    title: null,
  },
  activity: {
    id: '',
    title: null,
    type: CoachPlan.ActivityType.Workout,
    draft: true,
    completed: null,
    description: null,
    duration: null,
    position: 0,
    sequences: [],
    required_tools: [],
    calendar_day_id: 'YYYY-MM-DD',
    coach_plan_day_id: 'YYYY-MM-DD',
    version: 2,
  },
  session: {
    id: '',
    completed_exercises_avg_prev_5: null,
    difficulty_avg_prev_5: null,
    disliked_exercises_workouts_avg_prev_5: null,
    liked_exercises_avg_prev_5: null,
    comment: undefined,
    exercises_count: 0,
    calories: 0,
    duration: 0,
    coach_activity_id: '',
    coach_plan_day_id: '',
    difficulty: 0,
    exercise_count: 0,
    exercises: [],
    heart_rate_avg: 0,
    sequences: [],
    timestamp: null,
    title: '',
    title_resource: '',
    tools: [],
    type: HumanCoaching.SessionType.Custom,
    warmup: false,
  },
  sequences: {},
  containers: [],
  loading: false,
  isDirty: false,
}

/**
 * TODO: Exercise reducers mostly do the same thing, refactor them to reuse code.
 * (e.g. changeExerciseVolume, changeExerciseEffort, changeExerciseVoiceover, setExerciseExpectedDuration, setExerciseNote)
 */
export const activityBuilderSlice = createSlice({
  name: 'activityBuilder',
  initialState,
  reducers: {
    recalculateEstimatedValues: (
      state,
      {
        payload,
      }: {
        payload: {
          weight: number
          system: UnitSystem
          shouldRecalculateExercises: boolean
          exercisesDataCollection: ExercisesDataCollection
        }
        type: string
      }
    ) => {
      Object.keys(state.sequences).forEach((sequenceId: string) => {
        const sequenceExerciseDuration = getSequenceEstimatedDuration(
          state.sequences[sequenceId].exercises,
          payload.exercisesDataCollection
        )

        // If shouldRecalculateExercises === true; allow WoB to calc Reps / Duration (create/edit)
        if (payload.shouldRecalculateExercises) {
          recalculateSequenceExercisesRepsAndDuration(
            state.sequences[sequenceId].exercises,
            payload.exercisesDataCollection
          )
        }

        let sequenceExerciseActualDuration = 0

        if (state.session.sequences) {
          sequenceExerciseActualDuration = getSequenceActualDuration(
            state.sequences[sequenceId].exercises
          )
        }

        state.estimatedSequencesDuration[sequenceId] = {
          duration: sequenceExerciseDuration,
          actualDuration: sequenceExerciseActualDuration,
        }
      })

      const estimatedTotalActivityDuration = Object.keys(
        state.estimatedSequencesDuration
      ).reduce((prev, next) => {
        return prev + state.estimatedSequencesDuration[next].duration
      }, 0)

      state.estimatedDuration = estimatedTotalActivityDuration
      state.activity.duration = estimatedTotalActivityDuration

      state.estimatedCalories = getEstimatedCalories(
        { value: payload.weight, system: payload.system },
        estimatedTotalActivityDuration
      )
    },
    resetActivityBuilder: () => initialState,
    changeActivityTitle: (state, { payload }) => {
      state.activity.title = payload.title
      state.isDirty = true
    },
    setActivity: (
      state,
      {
        payload,
      }: {
        payload: IgnoreHistoryPayload<WithId<HumanCoaching.Activity>>
      }
    ) => {
      state.activity = payload.data
    },
    setDay: (state, { payload }: { payload: WithId<HumanCoaching.Day> }) => {
      state.day = payload
    },
    updateSequenceById: (
      state,
      {
        payload: { id, ...rest },
      }: { payload: { id: string; title?: string; voiceover?: string } }
    ) => {
      const sequence = state.sequences[id]
      if (sequence) {
        const updatedSequence = {
          ...sequence,
          ...rest,
        }
        state.sequences[id] = updatedSequence
        state.isDirty = true
      }
    },
    addRoundToSequence: (
      state,
      { payload }: { payload: { sequenceId: string }; type: string }
    ) => {
      let updatedSequence = {
        ...state.sequences[payload.sequenceId],
      }

      const updatedExercises = updatedSequence.exercises.map((exercise) => {
        const lastExerciseInstance =
          exercise.instances[exercise.instances.length - 1]

        const exerciseInstances = [
          ...exercise.instances,
          {
            ...lastExerciseInstance,
            round: getMaxSequenceRounds(updatedSequence.exercises) + 1,
          },
        ]
        return {
          ...exercise,
          instances: [...exerciseInstances],
        }
      })

      updatedSequence = {
        ...updatedSequence,
        exercises: [...updatedExercises],
      }

      state.sequences = {
        ...state.sequences,
        [updatedSequence.id]: {
          ...updatedSequence,
          maxRound: getMaxSequenceRounds(updatedSequence.exercises),
        },
      }
      state.isDirty = true
    },
    removeRoundFromSequence: (
      state,
      {
        payload,
      }: { payload: { sequenceId: string; round: number }; type: string }
    ) => {
      let updatedSequence = {
        ...state.sequences[payload.sequenceId],
      }

      const updatedExercises = removeRoundFromSequenceExercises(
        updatedSequence.exercises,
        payload.round
      )

      updatedSequence = {
        ...updatedSequence,
        exercises: [...updatedExercises],
      }

      state.sequences = {
        ...state.sequences,
        [updatedSequence.id]: {
          ...updatedSequence,
          maxRound: getMaxSequenceRounds(
            updatedSequence.exercises,
            updatedSequence.initialExerciseInstances
          ),
        },
      }
      state.isDirty = true
    },
    removeSequenceExercise: (
      state,
      {
        payload,
      }: {
        payload: {
          sequenceId: string
          exerciseId: string
        }
        type: string
      }
    ) => {
      let updatedSequence = {
        ...state.sequences[payload.sequenceId],
      }

      const updatedExercises = updatedSequence.exercises.filter(
        (exercise) => payload.exerciseId !== exercise.id
      )

      updatedSequence = {
        ...updatedSequence,
        exercises: [...updatedExercises],
      }

      state.sequences = {
        ...state.sequences,
        [updatedSequence.id]: {
          ...updatedSequence,
          maxRound: getMaxSequenceRounds(
            updatedSequence.exercises,
            updatedSequence.initialExerciseInstances
          ),
        },
      }
      state.isDirty = true
    },
    changeExerciseVolume: (
      state,
      {
        payload,
      }: {
        payload: {
          sequenceId: string
          exerciseId: string
          volumeType: CoachPlan.VolumeType
        }
        type: string
      }
    ) => {
      const updatedSequence = {
        ...state.sequences[payload.sequenceId],
      }

      const updatedExercises = updatedSequence.exercises.map((exercise) => {
        if (payload.exerciseId === exercise.id) {
          const isRepsBased = payload.volumeType === 'reps' ? true : false

          return {
            ...exercise,
            reps_based: isRepsBased,
            volume_type: payload.volumeType,
          }
        }

        return exercise
      })

      updatedSequence.exercises = [...updatedExercises]

      state.sequences = {
        ...state.sequences,
        [updatedSequence.id]: updatedSequence,
      }
      state.isDirty = true
    },
    changeExerciseEffort: (
      state,
      {
        payload,
      }: {
        payload: {
          sequenceId: string
          exerciseId: string
          effortType?: CoachPlan.EffortType
        }
        type: string
      }
    ) => {
      const updatedSequence = {
        ...state.sequences[payload.sequenceId],
      }

      const updatedExercises = updatedSequence.exercises.map((exercise) => {
        if (payload.exerciseId === exercise.id) {
          return {
            ...exercise,
            effort_type: payload.effortType,
          }
        }

        return exercise
      })

      updatedSequence.exercises = [...updatedExercises]

      state.sequences = {
        ...state.sequences,
        [updatedSequence.id]: updatedSequence,
      }
      state.isDirty = true
    },
    changeExerciseVoiceover: (
      state,
      {
        payload,
      }: {
        payload: {
          sequenceId: string
          exerciseId: string
          voiceover?: string
        }
      }
    ) => {
      const updatedSequence = {
        ...state.sequences[payload.sequenceId],
      }

      const updatedExercises = updatedSequence.exercises.map((exercise) => {
        if (payload.exerciseId === exercise.id) {
          return {
            ...exercise,
            voiceover: payload.voiceover,
          }
        }

        return exercise
      })

      updatedSequence.exercises = [...updatedExercises]

      state.sequences = {
        ...state.sequences,
        [updatedSequence.id]: updatedSequence,
      }
      state.isDirty = true
    },
    setExerciseExpectedDuration: (
      state,
      {
        payload,
      }: {
        payload: {
          sequenceId: string
          exerciseId: string
          expectedDuration?: number
        }
        type: string
      }
    ) => {
      const updatedSequence = {
        ...state.sequences[payload.sequenceId],
      }

      const updatedExercises = updatedSequence.exercises.map((exercise) => {
        if (payload.exerciseId === exercise.id) {
          return {
            ...exercise,
            volume_type: CoachPlan.VolumeType.Distance,
            duration: payload.expectedDuration,
          }
        }

        return exercise
      })

      updatedSequence.exercises = [...updatedExercises]

      state.sequences = {
        ...state.sequences,
        [updatedSequence.id]: updatedSequence,
      }
      state.isDirty = true
    },
    setExerciseNote: (
      state,
      {
        payload,
      }: {
        payload: {
          sequenceId: string
          exerciseId: string
          note?: string
        }
        type: string
      }
    ) => {
      const updatedSequence = {
        ...state.sequences[payload.sequenceId],
      }

      const updatedExercises = updatedSequence.exercises.map((exercise) => {
        return payload.exerciseId === exercise.id
          ? { ...exercise, note: payload.note, note_outdated: undefined }
          : exercise
      })

      updatedSequence.exercises = [...updatedExercises]

      state.sequences = {
        ...state.sequences,
        [updatedSequence.id]: updatedSequence,
      }
      state.isDirty = true
    },
    addNewExerciseInstance: (
      state,
      {
        payload,
      }: {
        payload: {
          sequenceId: string
          exerciseId: string
          exerciseInstance: CoachPlan.ExerciseInstance
        }
        type: string
      }
    ) => {
      const updatedSequence = {
        ...state.sequences[payload.sequenceId],
      }

      const updatedExercises = updatedSequence.exercises.map((exercise) => {
        let exerciseInstances: CoachPlan.ExerciseInstance[] = []

        if (payload.exerciseId === exercise.id) {
          exerciseInstances = [
            ...exercise.instances,
            {
              ...payload.exerciseInstance,
              round:
                payload.exerciseInstance.round < 0
                  ? Math.max(...exercise.instances.map((o) => o.round)) + 1
                  : payload.exerciseInstance.round,
            },
          ].sort((a, b) => a.round - b.round)
          return {
            ...exercise,
            instances: exerciseInstances,
          }
        }

        return {
          ...exercise,
        }
      })

      updatedSequence.exercises = [...updatedExercises]

      state.sequences = {
        ...state.sequences,
        [updatedSequence.id]: {
          ...updatedSequence,
          maxRound: getMaxSequenceRounds(updatedSequence.exercises),
        },
      }
      state.isDirty = true
    },
    removeExerciseInstance: (
      state,
      {
        payload,
      }: {
        payload: {
          sequenceId: string
          exerciseId: string
          exerciseInstanceIndex: number
        }
        type: string
      }
    ) => {
      const updatedSequence = {
        ...state.sequences[payload.sequenceId],
      }

      let updatedExercises = updatedSequence.exercises.map((exercise) => {
        let exerciseInstances: CoachPlan.ExerciseInstance[] = []
        if (payload.exerciseId === exercise.id) {
          exerciseInstances = exercise.instances.filter(
            (_exerciseInstance, exerciseInstanceIndex) =>
              exerciseInstanceIndex !== payload.exerciseInstanceIndex
          )

          return {
            ...exercise,
            instances: [...exerciseInstances],
          }
        }

        return {
          ...exercise,
        }
      })

      // Remove exercises with no instance
      updatedExercises = updatedExercises.filter(
        (exercise) => exercise.instances.length > 0
      )

      updatedExercises = removeEmptyRounds(updatedExercises)

      updatedSequence.exercises = [...updatedExercises]

      state.sequences = {
        ...state.sequences,
        [updatedSequence.id]: {
          ...updatedSequence,
          maxRound: getMaxSequenceRounds(
            updatedSequence.exercises,
            updatedSequence.initialExerciseInstances
          ),
        },
      }
      state.isDirty = true
    },
    changeExerciseInstanceInputValue: (
      state,
      {
        payload,
      }: {
        payload: {
          sequenceId: string
          exerciseId: string
          exerciseInstanceIndex: number
          type: string
          value: string | number | undefined
        }
        type: string
      }
    ) => {
      const updatedSequence = {
        ...state.sequences[payload.sequenceId],
      }

      const updatedExercises = updatedSequence.exercises.map((exercise) => {
        if (payload.exerciseId === exercise.id) {
          const exerciseInstances = exercise.instances.map(
            (exerciseInstance, exerciseInstanceIndex) => {
              if (payload.exerciseInstanceIndex === exerciseInstanceIndex) {
                return {
                  ...exerciseInstance,
                  [payload.type]: payload.value,
                }
              }
              return {
                ...exerciseInstance,
              }
            }
          )

          return {
            ...exercise,
            instances: [...exerciseInstances],
          }
        }

        return {
          ...exercise,
        }
      })

      updatedSequence.exercises = [...updatedExercises]

      state.sequences = {
        ...state.sequences,
        [updatedSequence.id]: updatedSequence,
      }
      state.isDirty = true
    },
    removeSequenceById: (
      state,
      { payload }: { payload: string; type: string }
    ) => {
      state.sequences = Object.keys(state.sequences)
        .filter((key) => key !== payload)
        .reduce((result: Record<string, CoachPlan.Sequence>, current) => {
          result[current] = state.sequences[current]
          return result
        }, {})
      state.containers = state.containers.filter((id) => id !== payload)

      state.estimatedSequencesDuration = Object.keys(state.sequences)
        .filter((key) => key !== payload)
        .reduce(
          (
            result: Record<
              string,
              { duration: number; actualDuration: number }
            >,
            current
          ) => {
            result[current] = {
              duration: state.estimatedSequencesDuration[current].duration,
              actualDuration:
                state.estimatedSequencesDuration[current].actualDuration,
            }
            return result
          },
          {}
        )

      state.isDirty = true
    },
    setSequences: (
      state,
      {
        payload,
      }: { payload: IgnoreHistoryPayload<Record<string, CoachPlan.Sequence>> }
    ) => {
      state.sequences = payload.data
      state.isDirty = true
    },
    setContainers: (state, { payload }) => {
      state.containers = payload
      state.isDirty = true
    },
    addSequence: (state, { payload }) => {
      state.sequences = {
        ...state.sequences,
        [payload.sequence.id]: {
          ...payload.sequence,
          initialExerciseInstances: payload.initialExerciseInstances,
          maxRound: payload.initialExerciseInstances - 1, // max round
          position: Object.keys(state.sequences).length, // calculate position
        },
      }

      state.estimatedSequencesDuration = {
        ...state.estimatedSequencesDuration,
        [payload.sequence.id]: {
          duration: 0,
        },
      }

      state.containers = [...state.containers, payload.sequence.id]
      state.isDirty = true
    },
    updateCustomExercisesInSequences: (
      state,
      { payload }: { payload: WithId<HumanCoaching.CustomExercise> }
    ) => {
      state.sequences = updateSequencesCustomExercises(state.sequences, payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActivityDetails.fulfilled, (state, action) => {
      state.containers = Object.keys(action.payload.sequences).sort((a, b) => {
        return (
          action.payload.sequences[a].position -
          action.payload.sequences[b].position
        )
      })

      state.day = action.payload.day
      state.activity = action.payload.activity

      if (
        action.payload.session &&
        action.payload.session.sequences &&
        action.payload.activity.completed
      ) {
        state.session = action.payload.session

        state.sequences = action.payload.session.sequences.reduce<
          Record<string, CoachPlan.Sequence>
        >((result, sequence) => {
          const maxRound = getMaxSequenceRounds(sequence.exercises)
          result[sequence.id] = {
            ...sequence,
            initialExerciseInstances: maxRound + 1,
            maxRound,
          }
          return result
        }, {})
      } else {
        state.sequences = Object.entries(action.payload.sequences).reduce<
          Record<string, CoachPlan.Sequence>
        >((result, [id, sequence]) => {
          const updatedSequence = {
            ...sequence,
            exercises: sequence.exercises.map((exercise) => {
              return {
                ...exercise,
                volume_type: !exercise.volume_type
                  ? exercise.reps_based
                    ? CoachPlan.VolumeType.Reps
                    : CoachPlan.VolumeType.Duration
                  : exercise.volume_type,
                id: uuidv4(),
              }
            }),
          }
          const maxRound = getMaxSequenceRounds(updatedSequence.exercises)
          result[id] = {
            ...updatedSequence,
            initialExerciseInstances: maxRound + 1,
            maxRound,
          }
          return result
        }, {})
      }
      state.estimatedSequencesDuration = Object.keys(state.sequences).reduce<
        Record<string, { duration: number; actualDuration: number }>
      >((result, current) => {
        result[current] = {
          duration: 0,
          actualDuration: 0,
        }
        return result
      }, {})
    })

    builder.addMatcher(
      isAnyOf(createActivity.pending, updateActivity.pending),
      (state) => {
        state.loading = true
        state.isDirty = false
      }
    )
    builder.addMatcher(
      isAnyOf(createActivity.fulfilled, updateActivity.fulfilled),
      (state) => {
        state.isDirty = false
      }
    )
    builder.addMatcher(
      isAnyOf(createActivity.rejected, updateActivity.rejected),
      (state) => {
        state.loading = false
        state.isDirty = true
      }
    )
  },
})

export const {
  addSequence,
  updateSequenceById,
  setContainers,
  removeSequenceById,
  setSequences,
  removeSequenceExercise,
  setExerciseExpectedDuration,
  setExerciseNote,
  removeExerciseInstance,
  changeActivityTitle,
  setDay,
  addNewExerciseInstance,
  setActivity,
  changeExerciseInstanceInputValue,
  resetActivityBuilder,
  removeRoundFromSequence,
  addRoundToSequence,
  recalculateEstimatedValues,
  changeExerciseEffort,
  changeExerciseVolume,
  changeExerciseVoiceover,
  updateCustomExercisesInSequences,
} = activityBuilderSlice.actions

export const activityBuilderSelector = (state: AppState) =>
  state.activityBuilder.present

// resetActivityBuilder can't be used since action is not passed to reducer when set as clearHistoryType in undoable
export const resetActivityBuilderHistoryAction = {
  type: 'activityBuilder/resetActivityBuilderHistory',
}
export const undoActivityBuilderHistoryAction = {
  type: 'activityBuilder/undoActivityBuilderHistory',
}
export const redoActivityBuilderHistoryAction = {
  type: 'activityBuilder/redoActivityBuilderHistory',
}

// Action types which should not push new history state
const historyPushExcludeActionTypes = [
  fetchActivityDetails.fulfilled.type,
  fetchActivityDetails.pending.type,
  fetchActivityDetails.rejected.type,
  createActivity.fulfilled.type,
  createActivity.pending.type,
  createActivity.rejected.type,
  updateActivity.fulfilled.type,
  updateActivity.pending.type,
  updateActivity.rejected.type,
  recalculateEstimatedValues.type,
  setDay.type,
  updateCustomExercisesInSequences.type,
]

// Filter out actions which should not push new history state, nevertheless actions still updates present state
const actionFilter: FilterFunction = (action) => {
  const isExcludedAction = historyPushExcludeActionTypes.some(
    (actionType) => actionType === action.type
  )
  if (isExcludedAction) {
    return false
  }
  const hasIgnoreHistoryFlag = action.payload?.ignoreHistory
  if (hasIgnoreHistoryFlag) {
    return false
  }
  return true
}

export default undoable(activityBuilderSlice.reducer, {
  clearHistoryType: resetActivityBuilderHistoryAction.type,
  filter: actionFilter,
  limit: 30,
  redoType: redoActivityBuilderHistoryAction.type,
  undoType: undoActivityBuilderHistoryAction.type,
  syncFilter: true, // Filter out actions will still update present state
})
