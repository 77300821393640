import { WithId, XOR } from 'fitify-types/src/types/common'
import { ExercisesData } from 'fitify-types/src/types/exercise-collection'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'

export const getPrimaryVolumeType = (
  exerciseDetails: XOR<ExercisesData, WithId<HumanCoaching.CustomExercise>>
) => {
  if (exerciseDetails.reps_supported || exerciseDetails.reps_preferred) {
    return HumanCoaching.VolumeType.Reps
  } else if (exerciseDetails.distance_supported) {
    return HumanCoaching.VolumeType.Distance
  } else {
    return HumanCoaching.VolumeType.Duration
  }
}

export const getPrimaryEffortType = (
  exerciseDetails: XOR<ExercisesData, WithId<HumanCoaching.CustomExercise>>
) => {
  if (exerciseDetails.weight_supported) {
    return HumanCoaching.EffortType.Weight
  } else {
    return undefined
  }
}

export const getSupportedVolumeTypes = (
  exerciseDetails: XOR<ExercisesData, WithId<HumanCoaching.CustomExercise>>
): HumanCoaching.VolumeType[] => {
  const isRepsSupported =
    exerciseDetails.reps_supported || exerciseDetails.reps_preferred

  return [
    ...(isRepsSupported ? [HumanCoaching.VolumeType.Reps] : []),
    HumanCoaching.VolumeType.Duration,
    ...(exerciseDetails.distance_supported
      ? [HumanCoaching.VolumeType.Distance]
      : []),
  ]
}

export const getSupportedEffortTypes = (
  exerciseDetails: XOR<ExercisesData, WithId<HumanCoaching.CustomExercise>>
): HumanCoaching.EffortType[] => {
  return [
    ...(exerciseDetails.weight_supported
      ? [HumanCoaching.EffortType.Weight]
      : []),
    HumanCoaching.EffortType.Custom,
  ]
}

export const getSupportedCombinedExerciseTypes = (
  exerciseDetails: XOR<ExercisesData, WithId<HumanCoaching.CustomExercise>>
): HumanCoaching.CombinedExerciseType[] => {
  const isRepsSupported =
    (exerciseDetails.reps ||
      exerciseDetails.reps_supported ||
      exerciseDetails.reps_preferred) &&
    !exerciseDetails.distance_supported

  return [
    ...(isRepsSupported && exerciseDetails.weight_supported
      ? [HumanCoaching.CombinedExerciseType.WeightReps]
      : []),
    ...(isRepsSupported ? [HumanCoaching.CombinedExerciseType.Reps] : []),
    HumanCoaching.CombinedExerciseType.Duration,
    ...(exerciseDetails.distance_supported
      ? [HumanCoaching.CombinedExerciseType.Distance]
      : []),
  ]
}

export const getEffortAndVolumeTypeByCombinedExerciseType = (
  combinedExerciseType: HumanCoaching.CombinedExerciseType
) => {
  switch (combinedExerciseType) {
    case HumanCoaching.CombinedExerciseType.WeightReps:
      return {
        effortType: HumanCoaching.EffortType.Weight,
        volumeType: HumanCoaching.VolumeType.Reps,
      }
    case HumanCoaching.CombinedExerciseType.Reps:
      return {
        volumeType: HumanCoaching.VolumeType.Reps,
      }
    case HumanCoaching.CombinedExerciseType.Distance:
      return {
        volumeType: HumanCoaching.VolumeType.Distance,
      }
    case HumanCoaching.CombinedExerciseType.Duration:
    default:
      return {
        volumeType: HumanCoaching.VolumeType.Duration,
      }
  }
}

export const getCombinedExerciseTypeByEffortAndVolumeType = (
  volumeType: HumanCoaching.VolumeType | undefined,
  effortType: HumanCoaching.EffortType | undefined
): HumanCoaching.CombinedExerciseType => {
  switch (volumeType) {
    case HumanCoaching.VolumeType.Reps: {
      if (effortType === HumanCoaching.EffortType.Weight) {
        return HumanCoaching.CombinedExerciseType.WeightReps
      }
      return HumanCoaching.CombinedExerciseType.Reps
    }
    case HumanCoaching.VolumeType.Distance:
      return HumanCoaching.CombinedExerciseType.Distance
    case HumanCoaching.VolumeType.Duration:
    default:
      return HumanCoaching.CombinedExerciseType.Duration
  }
}
