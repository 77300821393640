import { UnitSystem } from 'fitify-types/src/types/common'
import { User } from 'fitify-types/src/types/user'

import { UserState } from '@/store/user/userSlice'
import { Fitify } from '@/types/user'

export const userInitialState: UserState = {
  user: {
    uid: '',
    email: '',
    emailVerified: false,
    displayName: '',
    photoURL: '',
    disabled: false,
    metadata: {
      lastSignInTime: '',
      creationTime: '',
    },
    providerData: [],
    customClaims: {
      role: Fitify.User.Role.User,
    },
    tokensValidAfterTime: '',
    details: {
      features: {
        human_coaching: false,
      },
      registered: {
        _nanoseconds: 0,
        _seconds: 0,
      },
      plan: {
        progress: {
          week: 0,
          plan_day: 0,
          plan_id: 0,
          week_day: 0,
        },
        settings: {
          recovery_duration: 0,
          recovery_per_week: 0,
          warmup_duration: 0,
          workout_days: [],
          workouts_per_week: 0,
          workout_duration: 0,
        },
      },
      onboarding_completed: {
        workouts: false,
        hc: false,
      },
      ability: {
        flexibility: 0,
        cardio: 0,
        strength: 0,
      },
      coach: {
        coach_id: undefined,
        activity_images: {
          workout: {
            barbell: 0,
            bodyweight: 0,
            bosu: 0,
            dumbbell: 0,
            foamroller: 0,
            gym: 0,
            kettlebell: 0,
            medicineball: 0,
            pullupbar: 0,
            resistanceband: 0,
            swissball: 0,
            trx: 0,
          },
          warmup: 0,
          recovery: 0,
          custom: 0,
        },
      },
      stats: {
        session_count: 0,
      },
      profile: {
        newsletter: false,
        goal: undefined,
        latest_ideal_weight: 0,
        water_intake: 0,
        energy_level: 0,
        body_type: undefined,
        walking_duration: 0,
        height: 0,
        workout_frequency: 0,
        goal_weight: 0,
        gender: undefined,
        birthday: '',
        fitness: undefined,
        sleep_duration: 0,
        max_impact: 0,
        weight: 0,
        units: UnitSystem.Imperial,
        problem_areas: [],
        push_up_count: 0,
        bad_habits: [],
        typical_day: 0,
        plan_pace: 0,
        motivation_level: undefined,
        commitment: undefined,
        motivation: [],
        stress: undefined,
        previous_experience: 0,
        diet: User.Profile.Diet.None,
        excluded_ingredients: [],
        favorite_ingredients: [],
        cooking_preferences: [],
        hc_goals: [],
        hc_last_ideal_shape: 0,
        hc_typical_day: 0,
        hc_commitment: 0,
        hc_workouts_per_week: undefined,
        hc_workout_environment: [],
        hc_why_failed: [],
        hc_motivation: [],
        hc_coach_skills_focus: [],
        hc_coaching_style: [],
        device_os: undefined,
        device_apple_watch: false,
      },
    },
  },
  attribution: {
    user_id: '',
    source: '',
    medium: '',
    name: '',
    adgroup: '',
    keyword: '',
    creativeset: '',
  },
  search: null,
  weightRecords: null,
  loading: false,
  error: null,
  userPanelTags: null,
  registrationTokens: [],
  availableData: null,
  transactions: null,
  vo2MaxRecords: null,
  isLoaded: false,
}
