import { colors } from 'fitify-ui'
import styled, { css } from 'styled-components'

import { TextTypes } from '@/components/core/Typography/Text.Types'

export const StyledText = styled.p<TextTypes.StyledProps>`
  font-size: ${({ size }) => size}px;
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `};
  ${({ isUppercase }) =>
    isUppercase &&
    css`
      text-transform: uppercase;
    `}
  ${({ color }) => {
    if (color) {
      return css`
        color: ${colors[color]};
      `
    }
    return css`
      color: inherit;
    `
  }}
  ${({ variant }) => {
    switch (variant) {
      case 'headline': {
        return css`
          font-family: 'Graphik Wide Web', sans-serif;
        `
      }
      case 'headline-narrow':
      case 'button':
      case 'link':
      case 'label':
      case 'text':
      default: {
        return css`
          font-family: 'Graphik LCG Web', sans-serif;
        `
      }
    }
  }}
  ${({ variant }) => {
    switch (variant) {
      case 'headline':
      case 'headline-narrow':
      case 'button':
      case 'label': {
        return css`
          font-weight: 500;
          line-height: 1.2;
        `
      }
      case 'link':
        return css`
          font-weight: 500;
          line-height: 1.4;
          text-decoration: underline;
          cursor: pointer;
        `
      case 'text':
      default: {
        return css`
          font-weight: 400;
          line-height: 1.4;
        `
      }
    }
  }}
`
