import { type AxiosResponse } from 'axios'
import { CoachHelper } from 'fitify-types/src/types/coach-admin-api'

import { API_URL, apiClient } from '@/api'

export const AiService = {
  getAiSuggestionData: async ({
    userId,
  }: CoachHelper.Suggestion.InitialData.Request) => {
    const response: AxiosResponse<CoachHelper.Suggestion.InitialData.Response> =
      await apiClient.get(
        `${API_URL}/users/${userId}/ai-helper/suggestion-data`
      )

    return response.data
  },

  getSuggestion: async ({ userId, id }: CoachHelper.Suggestion.Request) => {
    const response: AxiosResponse<CoachHelper.Suggestion.Response> =
      await apiClient.get(
        `${API_URL}/users/${userId}/ai-helper/suggestion?type=${id}`
      )

    return response.data
  },

  createFeedback: async (
    data: CoachHelper.Suggestion.Feedback.Create.Request
  ) => {
    const response: AxiosResponse<CoachHelper.Suggestion.Feedback.Update.Response> =
      await apiClient.post(`${API_URL}/third-party/langsmith/feedback`, {
        feedbackId: data.feedbackId,
        key: data.subKey ? data.subKey : data.key,
        opts: data.opts,
        subKey: data.subKey,
        runId: data.runId,
      })
    return response.data
  },

  updateFeedback: async ({
    feedbackId,
    opts,
  }: CoachHelper.Suggestion.Feedback.Update.Request) => {
    const response: AxiosResponse<CoachHelper.Suggestion.Feedback.Update.Response> =
      await apiClient.put(
        `${API_URL}/third-party/langsmith/feedback/${feedbackId}`,
        {
          opts: opts,
        }
      )

    return response.data
  },
  deleteFeedback: async (feedbackId: string): Promise<void> => {
    await apiClient.del(
      `${API_URL}/third-party/langsmith/feedback/${feedbackId}`
    )
  },
}
