import { Timestamp } from 'firebase/firestore'

import { FirestoreTimestamp, WithId } from '../common'
import { ExerciseHistory } from '../exercise-history'

/**
 * Type to represent a human coaching plan
 */
export namespace HumanCoaching {
  /**
   * Type to represent a day range in the human coaching plan (from day to day)
   * @example
   * const range: DayRange = {
   *  from: '2023-05-01',
   *  to: '2023-05-31'
   * }
   */
  export type DayRange = {
    from: WithId<Day>['id']
    to: WithId<Day>['id']
  }

  export enum ActivityType {
    Warmup = 'warmup',
    Workout = 'workout',
    Recovery = 'recovery',
    Custom = 'custom',
  }

  export enum SequenceType {
    I = 'I',
    N = 'N',
    Z = 'Z',
  }

  export enum HeartRateZone {
    FIRST = 1,
    SECOND = 2,
    THIRD = 3,
    FOURTH = 4,
    FIFTH = 5,
  }

  export interface HeartRateZoneBpmRange {
    min: number
    max: number
  }

  export interface HeartRateZoneRecord<T> {
    title: string
    title_short: string
    duration: number
    minBPM: number
    maxBPM: number
    totalPercentageInActivity: number
    records?: T[]
    zone: HeartRateZone
  }

  export interface HeartRateSample {
    timestamp: FirestoreTimestamp
  }

  export interface FitifyHeartRateSample extends HeartRateSample {
    heart_rate: number
    offset: number
  }

  export interface NonFitifyHeartRateSample extends HeartRateSample {
    bpm: number
    offset: number
  }

  export type SequenceExerciseInstance = {
    actual_duration?: number
    actual_prep_duration?: number
    actual_weight?: number
    actual_reps?: number
    distance?: number
    actual_distance?: number
    round: number // The round this exercise instance belongs to, starting with 0
    prep_duration: number // The time for rest and preparation before the exercise in seconds
    duration?: number // The duration of the workout phase in seconds for duration based exercises
    reps?: number // The number of reps for reps based exercises
    weight?: number // The weight in kg for exercises with weights
    custom_effort?: string
  }

  export interface CustomExercise {
    title: string
    reps_double: boolean
    change_sides: boolean
    reps_supported: boolean
    distance_supported: boolean
    weight_supported: boolean
    created: Timestamp
    updated?: Timestamp
    tool?: string | null
    reps?: number
    description?: string
    image?: string
  }

  /**
   * Type to represent a custom exercise in a sequence. Since custom exercise may be updated during lifetime,
   * all the custom exercises in activities, which has not been completed yet, are changed according to latest version
   * of the custom exercise. The `has_been_updated` flag is used to indicate that the custom exercise has been updated
   * and changed in sequence. This flag is not persisted in the firestore.
   */
  export interface SequenceCustomExercise extends CustomExercise {
    has_been_updated?: boolean
  }

  export enum EffortType {
    Weight = 'weight',
    Custom = 'custom_effort',
  }

  export enum VolumeType {
    Duration = 'duration',
    Reps = 'reps',
    Distance = 'distance',
  }

  export enum CombinedExerciseType {
    WeightReps = 'weight_reps',
    Duration = 'duration',
    Reps = 'reps',
    Distance = 'distance',
  }

  export type SequenceExercise = {
    id: string
    code: string // The Exercise code from Exercise Collection (FitifyExercise.code / CustomExercise.id).
    reps_based?: boolean // @deprecated since volume_type added, volume_type='reps' should be used instead
    instances: SequenceExerciseInstance[]
    note?: string
    note_outdated?: boolean
    custom_exercise?: SequenceCustomExercise
    duration?: number
    effort_type?: EffortType
    volume_type?: VolumeType
    timestamp?: FirestoreTimestamp
    statistics?: ExerciseHistory.Statistics | null
    voiceover?: string
  }
  export interface Sequence {
    id: string
    title?: string // The sequence title
    type: SequenceType // The sequence type ("I", "N", "Z")
    duration?: number // Builder purpose - just holding calculated duration from exercises
    exercises: SequenceExercise[] // The list of sequence exercises
    position: number // The position of the sequence inside the activity
    initialExerciseInstances?: number
    maxRound: number // help to calc table
    voiceover?: string
  }

  export interface Day {
    title?: string | null // The day title
    completed?: FirestoreTimestamp // The completion timestamp if the day has been completed
    activities: WithId<Activity>[]
    draft?: boolean
    image?: string | null
    voiceover?: string
  }
  export interface Activity {
    position: number
    image?: string
    type: ActivityType | null // string | "warmup", "workout", "recovery", "custom"
    title: string | null // The activity title
    session_id?: string
    description?: string | null // The description for custom activities
    draft: boolean // True if the activity is in a draft state, false if it is finalized
    duration?: number | null // The duration in minutes for custom activities
    completed?: FirestoreTimestamp // The completion timestamp if the day has been completed
    sequences?: Sequence[]
    required_tools: string[]
    calendar_day_id: WithId<Day>['id'] // The day id the activity belongs to in calendar, it's different from coach_plan_day_id in case it's activity made from session which was completed on different day
    coach_plan_day_id: WithId<Day>['id'] // The coach plan day the activity is scheduled for
    version?: number
    voiceover?: string
    uid?: string
  }

  export interface NonFitifyMetaData {
    applehealth_type?: number
    name?: string
    summary_id?: string
    type?: number
    start_time: FirestoreTimestamp
    end_time: FirestoreTimestamp
  }

  export interface NonFitifyMovementDataSpeedSamples {
    timestamp: Timestamp
    speed_meters_per_second: number
    timer_duration_seconds: number
    has_speed_samples: boolean
  }

  export interface NonFitifyMovementData {
    avg_pace_minutes_per_kilometer?: number
    avg_speed_meters_per_second?: number
    max_pace_minutes_per_kilometer?: number
    max_speed_meters_per_second?: number
    has_speed_samples: boolean
  }

  export interface NonFitifyPositionSample {
    timestamp: FirestoreTimestamp
    coords_lat_lng_deg: number[]
    timer_duration_seconds: number
    activity_id: string
  }

  export interface NonFitifyPositionData {
    has_position_samples: boolean
  }

  export interface NonFitifyDistanceDataSummarySwimming {
    num_strokes?: number
    num_laps?: number
    pool_length_meters?: number
  }

  export interface NonFitifyDistanceDataSummaryElevation {
    gain_actual_meters?: number
    loss_actual_meters?: number
  }

  export interface NonFitifyDistanceDataSummary {
    swimming?: NonFitifyDistanceDataSummarySwimming
    elevation?: NonFitifyDistanceDataSummaryElevation
    steps?: number
    distance_meters?: number
  }

  export interface NonFitifyDistanceSample {
    timestamp: Timestamp
    distance_meters: number
    timer_duration_seconds: number
    activity_id: string
  }

  export interface NonFitifyElevationSample {
    timestamp: Timestamp
    elev_meters: number
    timer_duration_seconds: number
    activity_id: string
  }

  export interface NonFitifyDistanceData {
    summary?: NonFitifyDistanceDataSummary
    detailed: {
      has_step_samples: boolean
      has_distance_samples: boolean
      has_elevation_samples: boolean
    }
  }

  export interface NonFitifyCaloriesData {
    BMR_calories?: number
    total_burned_calories?: number
    net_activity_calories?: number
  }

  export interface NonFitifyHeartRateDataSummary {
    max_hr_bpm?: number
    min_hr_bpm?: number
    avg_hr_bpm?: number
  }

  export type HeartRateDataChartSample = {
    q1?: number
    median?: number
    q3?: number
    interQuantileRange?: number
    min?: number
    max?: number
  }
  export type HeartRateDataDetailedHrSampleChart<T> = T &
    HeartRateDataChartSample & {
      key: string
    }

  export interface NestChartHeartRateRecord extends HeartRateDataChartSample {
    primaryColor: string
    secondaryColor: string
  }

  export interface NonFitifyHeartRateDataDetailed {
    hr_samples?: NonFitifyHeartRateSample[]
    has_hr_samples: boolean
    activity_id: string
  }

  export interface NonFitifyHeartRateData {
    summary?: NonFitifyHeartRateDataSummary
    detailed?: NonFitifyHeartRateDataDetailed
  }

  export interface NonFitifyDurationsData {
    activity_seconds?: number
  }

  export interface NonFitifyAppleHealthSource {
    bundle_id: string
    name: string
    version?: string
    os_version: string
    product_type?: string
  }

  export interface NonFitifyActivity {
    metadata: NonFitifyMetaData
    applehealth_source: NonFitifyAppleHealthSource
    movement_data?: NonFitifyMovementData
    position_data?: NonFitifyPositionData
    distance_data?: NonFitifyDistanceData
    calories_data?: NonFitifyCaloriesData
    heart_rate_data?: NonFitifyHeartRateData
    active_durations_data?: NonFitifyDurationsData
  }

  export enum ExerciseFeedback {
    Favorite = 'FAVORITE',
    TooHard = 'TOO_HARD',
    TooEasy = 'TOO_EASY',
    Painful = 'PAINFUL',
    DifficultToPerform = 'DIFFICULT_TO_PERFORM',
    Other = 'OTHER',
  }
  export interface SessionExercise {
    get_ready_duration?: number
    code?: string
    duration?: number
    reps?: number
    feedback?: ExerciseFeedback
    feedback_other?: string // The user's written feedback (in case of feedback = OTHER)
    custom_exercise?: SequenceCustomExercise
    title?: string
    thumbnail?: string
  }

  export enum SessionType {
    Set = 'set',
    Custom = 'custom',
    Warmup = 'plan_warmup',
    Workout = 'plan_workout',
    Recovery = 'plan_recovery',
    Coach = 'coach',
    BreathWork = 'breathwork',
  }
  export interface Session {
    set_code?: string
    calories: number
    difficulty_avg_prev_5: number | null
    liked_exercises_avg_prev_5: number | null
    completed_exercises_avg_prev_5: number | null
    disliked_exercises_workouts_avg_prev_5: number | null
    difficulty?: number
    heart_rate_avg?: number
    heart_rate_max?: number
    heart_rate_avg_prev_5?: number
    duration: number
    exercises_count?: number
    exercises?: SessionExercise[]
    coach_activity_id: string
    coach_plan_day_id: string
    exercise_count?: number
    sequences?: Sequence[]
    timestamp?: FirestoreTimestamp
    title?: string
    title_resource?: string
    tools?: string[]
    type?: SessionType
    warmup?: boolean
    comment?: string
    coach_activity_type?: string
    app?: string
    plan_type: string | null
  }

  export type HcMessageAttachment = {
    id: number
    content_url: string
    content_type: string
    size: number
    width?: number
    height?: number
  }

  export type HcMessage = {
    coach_id?: string
    is_from_coach: boolean
    created_at: FirestoreTimestamp
    text?: string
    text_html?: string
    attachments?: HcMessageAttachment[]
  }

  export interface HumanCoachingSession extends Session {
    type: SessionType.Coach
    coach_day_id: string
  }
}
