import { EventEmitter } from 'events'

const eventEmitter = new EventEmitter()
const FITIFY_KEY_EVENT = 'fitify-key-event'

export type FitifyKeyEvent = {
  componentUuid: string
  event: KeyboardEvent
}

export const emitKeyEvent = (event: FitifyKeyEvent) => {
  eventEmitter.emit(FITIFY_KEY_EVENT, event)
}

export const addKeyEventListener = (
  listener: (props: FitifyKeyEvent) => void
) => {
  eventEmitter.addListener(FITIFY_KEY_EVENT, listener)
}

export const removeKeyEventListener = (
  listener: (props: FitifyKeyEvent) => void
) => {
  eventEmitter.removeListener(FITIFY_KEY_EVENT, listener)
}
