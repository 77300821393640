import { isNumber } from 'fitify-types/src/type-guards/common'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'

export type IsExerciseInstanceSkippedProps = Pick<
  HumanCoaching.SequenceExerciseInstance,
  'actual_duration'
> &
  Pick<HumanCoaching.SequenceExercise, 'volume_type'>

export const isExerciseInstanceSkipped = ({
  actual_duration,
  volume_type,
}: IsExerciseInstanceSkippedProps) => {
  if (isNumber(actual_duration)) {
    // Condition: Reps + Weight based exercise skipped Exercise took less than 10 seconds.
    // Message: Client clicked “✓” in first 10 seconds
    if (volume_type === HumanCoaching.VolumeType.Reps && actual_duration < 10) {
      return true
    }

    // Condition: Exercise took less than 10 seconds before marked as completed
    // Message: Client clicked “✓” in first 10 seconds
    if (
      volume_type === HumanCoaching.VolumeType.Distance &&
      actual_duration < 10
    ) {
      return true
    }

    // Condition: The exercise was skipped manually less then 5 seconds after start.
    // Message: Client skipped the exercise in first 5 seconds
    if (
      volume_type === HumanCoaching.VolumeType.Duration &&
      actual_duration < 5
    ) {
      return true
    }
  }

  return false
}
