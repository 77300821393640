import { createGlobalStyle } from 'styled-components'

import { zIndexes } from '@/theme/zIndexes'

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: 'Graphik LCG Web', 'Helvetica Neue', sans-serif;
  }

  a {
    color: inherit;
    line-height: 1;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  @keyframes spinner-loader-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes react-loading-skeleton {
    100% {
      transform: translateX(100%);
    }
  }

  .react-loading-skeleton {
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block; /* Enable animation */

    position: relative;
    z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
    display: inline-flex;
    width: 100%;
    overflow: hidden;
    line-height: 1;
    background-color: var(--base-color);
    border-radius: 0.25rem;
  }

  .react-loading-skeleton::after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: var(--pseudo-element-display);
    height: 100%;
    background-image: linear-gradient(
      90deg,
      var(--base-color),
      var(--highlight-color),
      var(--base-color)
    );
    background-repeat: no-repeat;
    transform: translateX(-100%);
    animation-name: react-loading-skeleton;
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: var(--animation-direction);
    content: ' ';
  }

  b,
  strong {
    font-weight: 500;
  }

  .react-tooltip.fitify-tooltip {
    z-index: ${zIndexes.tooltip};
    max-width: 200px;
    padding: 8px;
    font-size: 10px;
    line-height: 13px;
    background: #000;
    border-radius: 4px;
    opacity: 1;
  }
`

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-weight: 500;
    font-family: 'Graphik XXCond Web';
    font-style: normal;
    font-stretch: normal;
    src: url('https://static.gofitify.com/fonts/graphik/GraphikXXCondensed-Medium-Web.woff2') format('woff2'),
      url('https://static.gofitify.com/fonts/graphik/GraphikXXCondensed-Medium-Web.woff') format('woff');
  }

  @font-face {
    font-weight: 700;
    font-family: 'Graphik LCG Web';
    font-style: normal;
    font-stretch: normal;
    src: url('https://static.gofitify.com/fonts/graphik/Graphik-Bold-Cy-Gr-Web.woff2') format('woff2'),
      url('https://static.gofitify.com/fonts/graphik/Graphik-Bold-Cy-Gr-Web.woff') format('woff');
  }

  @font-face {
    font-weight: 600;
    font-family: 'Graphik LCG Web';
    font-style: normal;
    font-stretch: normal;
    src: url('https://static.gofitify.com/fonts/graphik/Graphik-Semibold-Cy-Gr-Web.woff2') format('woff2'),
      url('https://static.gofitify.com/fonts/graphik/Graphik-Semibold-Cy-Gr-Web.woff') format('woff');
  }

  @font-face {
    font-weight: 500;
    font-family: 'Graphik LCG Web';
    font-style: normal;
    font-stretch: normal;
    src: url('https://static.gofitify.com/fonts/graphik/Graphik-Medium-Cy-Gr-Web.woff2') format('woff2'),
      url('https://static.gofitify.com/fonts/graphik/Graphik-Medium-Cy-Gr-Web.woff') format('woff');
  }

  @font-face {
    font-weight: 400;
    font-family: 'Graphik LCG Web';
    font-style: normal;
    font-stretch: normal;
    src: url('https://static.gofitify.com/fonts/graphik/Graphik-Regular-Cy-Gr-Web.woff2') format('woff2'),
      url('https://static.gofitify.com/fonts/graphik/Graphik-Regular-Cy-Gr-Web.woff') format('woff');
  }

  @font-face {
    font-weight: 500;
    font-family: 'Graphik Wide Web';
    font-style: normal;
    font-stretch: normal;
    src: url('https://static.gofitify.com/fonts/graphik/GraphikWide-Medium-Web.woff2') format('woff2'),
      url('https://static.gofitify.com/fonts/graphik/GraphikWide-Medium-Web.woff') format('woff');
  }
`
