import { WithId } from 'fitify-types/src/types/common'
import {
  ExercisesDataCollection,
  FitifyExerciseData,
} from 'fitify-types/src/types/exercise-collection'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'
import {
  getSupportedEffortTypes,
  getSupportedVolumeTypes,
} from 'fitify-utils/src/exercises'

import { CoachPlan } from '@/types/CoachPlan'
import { CUSTOM_EXERCISE_REPS_PER_DURATION } from '@/utils/exercises'

export const getMaxSequenceRounds = (
  exercises: CoachPlan.SequenceExercise[],
  initialExerciseInstances = 0
) => {
  const maxRound = exercises.map((exercise) =>
    Math.max(...exercise.instances.map((b) => b.round))
  )

  if (maxRound.length > 0) {
    return Math.max(...maxRound) === -Infinity ? 0 : Math.max(...maxRound)
  }
  return Math.max(0, initialExerciseInstances - 1)
}

/**
 * Returns true if there are no exercise instances for the specified round, false otherwise.
 */
const isSequenceRoundEmpty = (
  exercises: CoachPlan.SequenceExercise[],
  round: number
) => {
  return exercises.every((exercise) => {
    return exercise.instances.every((instance) => instance.round !== round)
  })
}

/**
 * Removes a round from the sequence and removes any exercises that do not have any instances left.
 */
export const removeRoundFromSequenceExercises = (
  exercises: CoachPlan.SequenceExercise[],
  round: number
) => {
  const updatedExercises = exercises.map((exercise) => {
    const exerciseInstances = exercise.instances
      // Remove round
      .filter((exerciseInstance) => exerciseInstance.round !== round)
      // Shift next rounds
      .map((exerciseInstance) => {
        return {
          ...exerciseInstance,
          round:
            exerciseInstance.round > round
              ? exerciseInstance.round - 1
              : exerciseInstance.round,
        }
      })

    return {
      ...exercise,
      instances: [...exerciseInstances],
    }
  })

  return updatedExercises.filter((exercise) => exercise.instances.length > 0)
}

/**
 * Removes rounds with no exercise instances.
 */
export const removeEmptyRounds = (exercises: CoachPlan.SequenceExercise[]) => {
  let updatedExercises = exercises
  for (let round = 0; round < getMaxSequenceRounds(updatedExercises); round++) {
    if (isSequenceRoundEmpty(exercises, round)) {
      updatedExercises = removeRoundFromSequenceExercises(
        updatedExercises,
        round
      )
    }
  }
  return updatedExercises
}

/**
 * Updates the sequence exercises according to current custom exercise settings
 */
export const updateSequencesCustomExercises = (
  sequences: Record<string, CoachPlan.Sequence>,
  customExercise: WithId<HumanCoaching.CustomExercise>
) => {
  const updatedSequences = {} as Record<string, CoachPlan.Sequence>

  Object.keys(sequences).forEach((sequenceId: string) => {
    const updatedSequenceExercises = sequences[sequenceId].exercises.map(
      (exercise) => {
        if (exercise.code === customExercise.id) {
          const supportedVolumeTypes = getSupportedVolumeTypes(customExercise)
          const hasSupportedVolumeType =
            exercise.volume_type &&
            supportedVolumeTypes.includes(exercise.volume_type)
          const supportedEffortTypes = getSupportedEffortTypes(customExercise)
          const hasSupportedEffortType =
            !exercise.effort_type ||
            supportedEffortTypes.includes(exercise.effort_type)

          return {
            ...exercise,
            change_sides: customExercise.change_sides,
            reps_based: exercise.volume_type === HumanCoaching.VolumeType.Reps,
            custom_exercise: customExercise,
            volume_type: hasSupportedVolumeType
              ? exercise.volume_type
              : CoachPlan.VolumeType.Duration,
            effort_type: hasSupportedEffortType
              ? exercise.effort_type
              : undefined,
          }
        }
        return exercise
      }
    )

    const updatedSequence = {
      ...sequences[sequenceId],
      exercises: updatedSequenceExercises,
    }

    updatedSequences[sequenceId] = {
      ...updatedSequence,
    }
  })

  return updatedSequences
}

/**
 * Recalculates the reps and duration of the sequence exercises based on the source exercise data
 */
export const recalculateSequenceExercisesRepsAndDuration = (
  exercises: CoachPlan.SequenceExercise[],
  exercisesData: ExercisesDataCollection
) => {
  exercises.forEach((exercise) => {
    const sourceExercise = exercisesData[exercise.code] as FitifyExerciseData
    const sourceExerciseDuration = exercise.custom_exercise
      ? CUSTOM_EXERCISE_REPS_PER_DURATION
      : sourceExercise.duration
    const sourceExerciseReps = exercise.custom_exercise
      ? exercise.custom_exercise.reps
      : sourceExercise.reps

    if (sourceExerciseReps) {
      exercise.instances.forEach((instance) => {
        if (exercise.volume_type === CoachPlan.VolumeType.Duration) {
          const reps = Math.round(
            (sourceExerciseReps / sourceExerciseDuration) * instance.duration
          )
          if (
            sourceExercise?.reps_double ||
            exercise.custom_exercise?.reps_double
          ) {
            instance.duration =
              instance.duration % 2 === 1
                ? instance.duration + 1
                : instance.duration
            instance.reps = reps % 2 === 1 ? reps + 1 : reps
          } else {
            instance.reps = reps
          }
        }
        if (exercise.volume_type === CoachPlan.VolumeType.Reps) {
          const duration = Math.round(
            (sourceExerciseDuration / sourceExerciseReps) * instance.reps
          )
          if (
            sourceExercise?.reps_double ||
            exercise.custom_exercise?.reps_double
          ) {
            instance.duration = duration % 2 === 1 ? duration + 1 : duration
            instance.reps =
              instance.reps % 2 === 1 ? instance.reps + 1 : instance.reps
          } else {
            instance.duration = duration
          }
        }
      })
    }
  })
}
