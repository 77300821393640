/**
 * Countries translations taken from https://www.npmjs.com/package/i18n-iso-countries package
 */
import enCountries from '../../../public/locales/en/countries.json'
import enFitifyAdmin from '../../../public/locales/en/fitify-admin.json'
import enFitifyCore from '../../../public/locales/en/fitify-core.json'
import enFitifyHcOnboarding from '../../../public/locales/en/fitify-hc-onboarding.json'
import enFitifyWorkouts from '../../../public/locales/en/fitify-workouts.json'
import enFitifyYoga from '../../../public/locales/en/fitify-yoga.json'
import enInstructions from '../../../public/locales/en/instructions.json'

export const enResources = {
  countries: enCountries,
  'fitify-admin': enFitifyAdmin,
  'fitify-core': enFitifyCore,
  'fitify-hc-onboarding': enFitifyHcOnboarding,
  'fitify-workouts': enFitifyWorkouts,
  'fitify-yoga': enFitifyYoga,
  instructions: enInstructions,
} as const
