import { UnitSystem } from 'fitify-types/src/types/common'
import { User } from 'fitify-types/src/types/user'

import { Fitify } from '@/types/user'

export const mapDefaultValues = (user: Fitify.User): Fitify.User => {
  return {
    ...user,
    details: {
      ...user.details,
      profile: {
        ...(user.details?.profile ?? {}),
        units: user.details?.profile?.units ?? UnitSystem.Imperial,
      } as User.Profile,
    },
  }
}

export const isLatestDeviceAndroid = (
  registrationTokens: User.RegistrationToken[]
): boolean => {
  const latestDevice = registrationTokens?.[0]

  if (latestDevice) {
    return latestDevice.platform === 'android'
  }

  return false
}
