import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { emitKeyEvent } from '@/components/ui/KeyEventHandler/events'
import { uiSelector } from '@/store/ui/uiSlice'

export const KeyEventEmitter = () => {
  const { keyEventComponentsUuids } = useSelector(uiSelector)
  const componentUuid = useRef<string>()

  useEffect(() => {
    if (keyEventComponentsUuids.length > 0) {
      componentUuid.current =
        keyEventComponentsUuids[keyEventComponentsUuids.length - 1]
    } else {
      componentUuid.current = undefined
    }
  }, [keyEventComponentsUuids])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (componentUuid.current) {
        emitKeyEvent({ componentUuid: componentUuid.current, event })
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [componentUuid])

  return null
}
