import { HumanCoaching } from 'fitify-types/src/types/human-coaching'

export const getSkippedExerciseCountInSequence = (
  exercises: HumanCoaching.SequenceExercise[]
) => {
  return exercises.reduce((prevValue, currentExercise) => {
    const skippedCount = currentExercise.instances.reduce(
      (prevInstanceValue, currentInstanceValue) => {
        let skippedExercise = false
        if (
          currentInstanceValue.actual_duration &&
          (currentExercise.reps_based ||
            currentExercise.volume_type === HumanCoaching.VolumeType.Reps ||
            currentExercise.volume_type ===
              HumanCoaching.VolumeType.Distance) &&
          currentInstanceValue.actual_duration < 10
        ) {
          skippedExercise = true
        } else if (
          currentExercise.volume_type === HumanCoaching.VolumeType.Duration &&
          currentInstanceValue.actual_duration &&
          currentInstanceValue.duration &&
          currentInstanceValue.actual_duration < currentInstanceValue.duration
        ) {
          skippedExercise = true
        } else if (currentInstanceValue.actual_duration === 0) {
          skippedExercise = true
        }

        const isSkipped = skippedExercise ? 1 : 0
        return prevInstanceValue + isSkipped
      },
      0
    )
    return prevValue + skippedCount
  }, 0)
}
