import { isMacOs } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { ToastMessage } from '@/components/core/Toast/ToastMessage'
import { undoActivityBuilderHistoryAction } from '@/store/activity/builder/builderSlice'
import { useAppDispatch } from '@/store/hooks'
import { Fitify } from '@/types/user'

export const useToastMessages = () => {
  const showToast = (props: Fitify.ToastMessage) => {
    toast<Pick<Fitify.ToastMessage, 'message'>>(<ToastMessage {...props} />, {
      data: { message: props.message },
    })
  }

  return {
    showToast,
  }
}

export const useUndoToastMessages = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { showToast } = useToastMessages()

  const showUndoToast = ({ message }: Pick<Fitify.ToastMessage, 'message'>) => {
    const shortcut = isMacOs ? '(⌘ Z)' : '(Ctrl+Z)'
    showToast({
      message,
      type: 'info',
      action: {
        text: `${t('undo')} ${shortcut}`,
        onClick: () => {
          dispatch(undoActivityBuilderHistoryAction)
        },
      },
    })
  }

  return {
    showUndoToast,
  }
}
