import { StyledText } from '@/components/core/Typography/Text.Styled'
import { TextTypes } from '@/components/core/Typography/Text.Types'

export const Text = ({
  align = 'left',
  as,
  children,
  color,
  isUppercase = false,
  size = 14,
  variant = 'text',
  ...htmlProps
}: TextTypes.Props) => {
  return (
    <StyledText
      {...htmlProps}
      align={align}
      as={as ?? 'p'}
      color={color}
      isUppercase={isUppercase}
      size={size}
      variant={variant}
    >
      {children}
    </StyledText>
  )
}
