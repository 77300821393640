import { toBoolean } from 'fitify-utils/src/properties'
import StackdriverErrorReporter from 'stackdriver-errors-js'

let errorHandler: StackdriverErrorReporter | null = null

if (
  typeof window !== 'undefined' &&
  toBoolean(process.env.NEXT_PUBLIC_STACKDRIVER_ERROR_REPORTING_ENABLED)
) {
  errorHandler = new StackdriverErrorReporter()
  errorHandler.start({
    key: process.env.NEXT_PUBLIC_API_KEY || '',
    projectId: process.env.NEXT_PUBLIC_PROJECT_ID || '',
    service: 'fitify-coach-admin',
    version: '2',
  })
}

export const getErrorHandler = () => {
  return errorHandler
}
