import { colors, StyledIcon } from 'fitify-ui'
import styled, { css } from 'styled-components'

import { ButtonTypes } from './Button.Types'

const resolveVariantStyle = (variant: ButtonTypes.Variants = 'primary') =>
  ({
    primary: css`
      background: ${colors.Green400};

      &:hover,
      &:focus-visible {
        background: ${colors.Green700};
      }

      &:active {
        background: ${colors.Green100};
        transform: scale(0.95);
      }
    `,
    secondary: css`
      background: ${colors.Gray200};

      &:hover,
      &:focus-visible {
        background: ${colors.Gray300};
      }

      &:active {
        background: ${colors.Gray50};
        transform: scale(0.95);
      }
    `,
    login: css`
      background: ${colors.Blue200};

      span {
        margin: 0 auto;
        text-align: center;
      }
      span,
      svg {
        color: ${colors.White};
      }

      &:hover,
      &:focus-visible {
        background: ${colors.Blue400};
      }

      &:active {
        background: ${colors.Blue400};
        transform: scale(0.95);
      }
    `,
    white: css`
      background: ${colors.White};

      &:hover,
      &:focus-visible {
        background: ${colors.Gray400};
      }

      &:active {
        background: ${colors.Gray600};
        transform: scale(0.95);
      }
    `,
    black: css`
      background: ${colors.Black};

      span,
      svg {
        color: ${colors.White};
      }

      &:hover,
      &:focus-visible {
        background: ${colors.Gray800};
      }

      &:active {
        background: ${colors.Gray800};
        transform: scale(0.95);
      }
    `,
    'black-outline': css`
      background: ${colors.White};
      box-shadow: 0 0 0 1px ${colors.Black} inset;

      span,
      svg {
        color: ${colors.Black};
      }

      &:hover,
      &:focus-visible {
        background: ${colors.Black};

        span,
        svg {
          color: ${colors.White};
        }
      }

      &:active {
        background: ${colors.Gray800};
        transform: scale(0.95);

        span,
        svg {
          color: ${colors.White};
        }
      }
    `,
  })[variant]

const resolveDisabledStyle = (
  variant: ButtonTypes.Variants = 'primary',
  disabled?: boolean
) => {
  if (disabled) {
    switch (variant) {
      case 'black-outline': {
        return css`
          background-color: transparent;
          box-shadow: none;
          cursor: not-allowed;
          span,
          svg {
            color: ${colors.Gray500};
          }
        `
      }
      default: {
        return css`
          background-color: ${colors.Gray300};
          cursor: not-allowed;
          span,
          svg {
            color: ${colors.Gray500};
          }
        `
      }
    }
  }

  return undefined
}

const resolveSizeStyle = (size: ButtonTypes.Sizes = 'md') =>
  ({
    md: css`
      height: 32px;
      padding: 0 16px;
    `,
    sm: css`
      height: 24px;
      padding: 0 12px;
    `,
  })[size]

export const ButtonStyles = styled.button<ButtonTypes.StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 54px;
  ${(props) => resolveVariantStyle(props.variant)};
  ${(props) => resolveSizeStyle(props.size)};
  cursor: pointer;
  transition: 100ms all ease-in-out;
  user-select: none;
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  &[disabled] {
    ${(props) => resolveDisabledStyle(props.variant, props.disabled)};
  }
`

export const ContentWrapper = styled.div<
  Pick<ButtonTypes.Props, 'iconPosition'>
>`
  display: flex;
  flex-direction: ${(props) =>
    props.iconPosition === 'right' ? 'row-reverse' : 'row'};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`

export const TextWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const resolveIconSize = (size: ButtonTypes.Sizes = 'md') =>
  ({
    md: css`
      ${StyledIcon} {
        width: 16px;
        height: 16px;
      }
    `,
    sm: css`
      ${StyledIcon} {
        width: 12px;
        height: 12px;
      }
    `,
  })[size]

export const IconWrapper = styled.div<Pick<ButtonTypes.Props, 'size'>>`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  ${(props) => resolveIconSize(props.size)};
`
