import { type AxiosResponse } from 'axios'

import { API_URL, apiClient } from '@/api'

export const NonFitifyActivitiesService = {
  getActivitySamples: async <T>(
    userId: string,
    type:
      | 'heart-rate-samples'
      | 'distance-samples'
      | 'elevation-samples'
      | 'position-samples',
    nonFitifyActivityId: string
  ): Promise<T> => {
    const response: AxiosResponse = await apiClient.get(
      `${API_URL}/users/${userId}/applehealth-activities/${nonFitifyActivityId}/${type}`
    )
    return response.data.data
  },
}
