import { Timestamp } from 'firebase/firestore'
import { isSerializableTimestamp } from 'fitify-types/src/type-guards/common'
import { FirestoreTimestamp } from 'fitify-types/src/types/common'
import { DateTime } from 'luxon'

export const createFirebaseTimestamp = (
  timestamp?: FirestoreTimestamp
): Timestamp | null => {
  return timestamp && isSerializableTimestamp(timestamp)
    ? new Timestamp(timestamp?._seconds, timestamp?._nanoseconds)
    : null
}

export const getDateFromDateString = (dateString: string): Date => {
  return DateTime.fromFormat(dateString, 'yyyy-MM-dd').toJSDate()
}

export const differenceInCalendarDays = (date1: Date, date2: Date): number => {
  const firstDate = DateTime.fromJSDate(date1).toUTC().startOf('day')
  const secondDate = DateTime.fromJSDate(date2).toUTC().startOf('day')
  return firstDate.diff(secondDate, 'days').days
}

export const differenceInMinutes = (date1: Date, date2: Date): number => {
  return DateTime.fromJSDate(date1).diff(DateTime.fromJSDate(date2), 'minutes')
    .minutes
}

export const addDays = (date: Date, days: number): Date => {
  return DateTime.fromJSDate(date).plus({ days }).toJSDate()
}
