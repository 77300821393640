import { colors } from 'fitify-ui'
import styled, { css } from 'styled-components'

import { Text } from '@/components/core/Text/Text.Types'

const resolveVariantStyle = (variant: Text.StyledProps) =>
  ({
    h1: css`
      font-weight: 400;
      font-size: 21px;
      font-family: 'Graphik Wide Web', sans-serif;
      line-height: 21px;
    `,
    h2: css`
      font-size: 16px;
    `,
    h3: css`
      font-size: 14px;
    `,
    h4: css`
      font-size: 13px;
    `,
    h5: css`
      font-size: 12px;
    `,
    h6: css`
      font-size: 11px;
    `,
    xxl: css`
      font-size: 23px;
    `,
    xl: css`
      font-size: 21px;
      line-height: 1.2;
    `,
    lg: css`
      font-size: 16px;
      line-height: 1.2;
    `,
    md: css`
      font-size: 14px;
      line-height: 1.2;
    `,
    sm: css`
      font-size: 12px;
      line-height: 1.2;
    `,
    xs: css`
      font-size: 11px;
    `,
    xxs: css`
      font-size: 10px;
      line-height: 1.2;
    `,
    xxxs: css`
      font-size: 9px;
      line-height: 1.2;
    `,
    xxxxs: css`
      font-size: 8px;
      line-height: 1.2;
    `,
    'category-health-panel': css`
      font-weight: 500;
      font-size: 8px;
      line-height: 1.1;
      text-transform: uppercase;
    `,
    'chip-heartrate-zone-number': css`
      font-size: 7px;
      line-height: 1;
    `,
    'chip-heartrate-type': css`
      font-size: 6px;
      line-height: 1.1;
    `,
    'sequence-type': css`
      font-weight: 500;
      font-size: 11px;
      font-family: 'Graphik LCG Web', sans-serif;
      line-height: 11px;
    `,
    'section-title': css`
      margin: 45px 0 15px;
      font-size: 12px;
      color: ${colors.Black};
      font-weight: 500;
      line-height: 24px;
    `,
    'overview-no-activities': css`
      color: ${colors.Gray500};
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
    `,
  })[variant.variant]

/** @deprecated use '@/components/core/Typography/Text' instead */
export const TextStyles = styled.p<Text.StyledProps>`
  ${(props) =>
    props.fontFamily
      ? css`
          font-family: ${props.fontFamily};
        `
      : css`
          font-family: 'Graphik LCG Web', sans-serif;
        `}

  ${(props) =>
    props.fontWeight
      ? css`
          font-weight: ${props.fontWeight};
        `
      : css`
          font-weight: 400;
        `}

  ${(props) =>
    props.color
      ? css`
          color: ${colors[props.color]};
        `
      : css`
          color: inherit;
        `}

  ${(props) => resolveVariantStyle(props)};
`
