import { Timestamp } from 'firebase/firestore'
import { WithId } from 'fitify-types/src/types/common'
import { ExerciseHistory } from 'fitify-types/src/types/exercise-history'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'

/**
 * @depracated - use HumanCoaching instead
 */
export namespace CoachPlan {
  export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never } & U
  export type XOR<T, U> = Without<T, U> | Without<U, T>

  export interface DayCopy {
    id: string
    sourceUserId: string
    isCut: boolean
  }

  export enum ActivityType {
    Warmup = 'warmup',
    Workout = 'workout',
    Recovery = 'recovery',
    Custom = 'custom',
  }

  export enum ExerciseFeedback {
    Favorite = 'FAVORITE',
    TooHard = 'TOO_HARD',
    TooEasy = 'TOO_EASY',
    Painful = 'PAINFUL',
    DifficultToPerform = 'DIFFICULT_TO_PERFORM',
    Other = 'OTHER',
  }

  export interface SessionExercise {
    get_ready_duration?: number
    code?: string
    duration?: number
    feedback?: ExerciseFeedback
    feedback_other?: string // The user's written feedback (in case of feedback = OTHER)
  }

  export interface Session {
    calories: number
    difficulty_avg_prev_5: number | null
    liked_exercises_avg_prev_5: number | null
    completed_exercises_avg_prev_5: number | null
    disliked_exercises_workouts_avg_prev_5: number | null
    difficulty?: number
    heart_rate_avg?: number
    heart_rate_max?: number
    heart_rate_avg_prev_5?: number
    duration: number
    exercises_count?: number
    exercises?: SessionExercise[]
    coach_activity_id?: string
    coach_plan_day_id?: string
    exercise_count?: number
    sequences?: Sequence[]
    timestamp?: Timestamp | null
    title?: string
    title_resource?: string
    tools?: string[]
    type?: HumanCoaching.SessionType
    warmup?: boolean
    comment?: string
  }

  export interface Exercise {
    code: string
    get_ready_duration: number
    duration?: number
    title: string
    thumbnail: null | string
    reps?: number
  }

  export type ActivityCopy = Pick<
    WithId<HumanCoaching.Activity>,
    'id' | 'coach_plan_day_id'
  > & {
    sourceUserId: string
    isCut: boolean
  }

  export interface RemoveActivity {
    id: string
    date: string
  }

  export interface ActivityDate {
    year: number
    month: number
    date: number
  }

  export type ActivityBuilderSequences = Record<string, Sequence>

  export interface Sequence {
    id: string
    title?: string // The sequence title
    type: SequenceType // The sequence type ("I", "N", "Z")
    duration?: number // Builder purpose - just holding calculated duration from exercises
    exercises: SequenceExercise[] // The list of sequence exercises
    position: number // The position of the sequence inside the activity
    initialExerciseInstances?: number
    maxRound: number // help to calc table
    voiceover?: string
  }

  export enum SequenceType {
    I = 'I',
    N = 'N',
    Z = 'Z',
  }

  export interface GeneratedExercise {
    code: string
    duration: number
    get_ready_duration: number
    reps: number
    round: number
  }

  export interface ExerciseInstance {
    id: string
    actual_duration?: number
    actual_prep_duration?: number
    actual_weight?: number
    actual_reps?: number
    distance?: number
    round: number // The round this exercise instance belongs to, starting with 0
    prep_duration: number // The time for rest and preparation before the exercise in seconds
    duration: number // The duration of the workout phase in seconds for duration based exercises
    reps: number // The number of reps for reps based exercises
    weight: number // The weight in kg for exercises with weights
    custom_effort?: string
  }

  export type SequenceExerciseInstance = {
    id: string
    actual_duration?: number
    actual_prep_duration?: number
    actual_weight?: number
    actual_reps?: number
    distance?: number
    actual_distance?: number
    round: number // The round this exercise instance belongs to, starting with 0
    prep_duration: number // The time for rest and preparation before the exercise in seconds
    duration: number // The duration of the workout phase in seconds for duration based exercises
    reps: number // The number of reps for reps based exercises
    weight: number // The weight in kg for exercises with weights
    custom_effort?: string
  }

  export type SequenceExercise = {
    id: string
    code: string // The Exercise code from Exercise Collection (FitifyExercise.code / CustomExercise.id).
    reps_based: boolean // The exercise is reps based.
    instances: SequenceExerciseInstance[]
    note?: string
    note_outdated?: boolean
    custom_exercise?: WithId<HumanCoaching.SequenceCustomExercise>
    duration?: number
    effort_type?: EffortType
    volume_type?: VolumeType
    statistics?: ExerciseHistory.Statistics | null
    position?: number
    voiceover?: string
  }

  export enum EffortType {
    Weight = 'weight',
    Custom = 'custom_effort',
  }

  export enum VolumeType {
    Duration = 'duration',
    Reps = 'reps',
    Distance = 'distance',
  }

  export type CustomExercisePayload = {
    userId: string
    customExercise: Partial<WithId<HumanCoaching.CustomExercise>>
  }

  export type ExerciseInstanceState =
    | 'skipped'
    | 'modified'
    | 'prescribed'
    | 'editable'
}
