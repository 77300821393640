import styled from 'styled-components'

import { ButtonTypes } from '@/components/core/Button/Button.Types'

type Props = {
  className?: string
} & Pick<ButtonTypes.StyledProps, 'disabled' | 'size'>

const Path = styled.path`
  transform-origin: center;
  animation: spinner 1.4s linear infinite;

  @keyframes spinner {
    100% {
      transform: rotate(360deg);
    }
  }
`

export const ButtonLoader = ({ className, size }: Props) => {
  const dimensions = size === 'sm' ? 16 : 20
  return (
    <svg
      className={className}
      width={dimensions}
      height={dimensions}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M12,23a9.63,9.63,0,0,1-8-9.5,9.51,9.51,0,0,1,6.79-9.1A1.66,1.66,0,0,0,12,2.81h0a1.67,1.67,0,0,0-1.94-1.64A11,11,0,0,0,12,23Z"
        fill="currentColor"
      />
    </svg>
  )
}
