export const validateEmail = (email: string) => {
  const regEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regEmail.test(email)
}

export const isWholeNumber = (input: number) => input % 1 === 0

export const isValueInRange = (value: number, min: number, max: number) => {
  return value >= min && value <= max
}

export const MIN_PREP_DURATION = 0
export const MAX_PREP_DURATION = 999

export const DEFAULT_PREP_DURATION = 10
export const DEFAULT_PREP_DURATION_NON_FIRST_SEQUENCE = 15

export const MIN_DURATION = 5
export const MAX_DURATION = 999

export const MIN_REPS = 1
export const MAX_REPS = 100

export const MIN_DISTANCE_KM = 0.1
export const MAX_DISTANCE_KM = 42.2

export const MIN_EFFORT_WEIGHT = 0
export const MAX_EFFORT_WEIGHT = 999

export const CUSTOM_EFFORT_MAX_CHARACTERS_LIMIT = 20
