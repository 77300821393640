type TableRow = { [key: string]: string }

export const parseMarkdownTable = <T extends TableRow>(
  markdownTable: string
): T[] => {
  const rows = markdownTable.split('\n').filter((row) => row.trim() !== '')

  const headers = rows[0]
    .split('|')
    .map((header) => header.trim().toLowerCase())
    .filter((header) => header !== '')

  const dataRows = rows.slice(2) // Skip the separator row
  const result: T[] = []

  for (const row of dataRows) {
    const rowData = row.split('|').map((data) => data.trim())

    const filteredRowData = rowData.filter(
      (data, index) => index !== 0 && data !== ''
    )

    const rowObject: Partial<T> = {}
    headers.forEach((header, index) => {
      rowObject[header as keyof T] = filteredRowData[
        index
      ] as unknown as T[keyof T]
    })
    result.push(rowObject as T)
  }

  return result
}

export const kebabCase = (str: string) => {
  const matches = str.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
  )
  return matches ? matches.join('-').toLowerCase() : ''
}

export const createLangsmithLink = (
  langsmithOrganisationId: string,
  langsmithProjectId: string,
  chainRunId: string | null | undefined
) => {
  return `https://smith.langchain.com/o/${langsmithOrganisationId}/projects/p/${langsmithProjectId}?peek=${chainRunId}`
}
