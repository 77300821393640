import {
  EquipmentListData,
  FitifyExerciseData,
  FitifyExercisesDataCollection,
} from 'fitify-types/src/types/exercise-collection'

export const loadEquipments = async () => {
  return (await import('../data/exercises/equipment-list.json'))
    .default as EquipmentListData[]
}

type ExerciseByToolImport = {
  default: {
    exercises: FitifyExerciseData[]
  }
}

export const loadExercises = async () => {
  const data = (await import(
    '../data/exercises/exercises.json'
  )) as ExerciseByToolImport
  return data.default.exercises
}

export const loadExercisesAsObject = async () => {
  const exercises = await loadExercises()
  return exercises.reduce<FitifyExercisesDataCollection>((acc, exercise) => {
    acc[exercise.code] = exercise
    return acc
  }, {}) satisfies FitifyExercisesDataCollection
}

const loadExercisesToolsData = (toolCode: string) => {
  switch (toolCode) {
    case 'barbell':
      return import(
        '../data/exercises/by-tools/exercises-barbell.json'
      ) as Promise<ExerciseByToolImport>
    case 'bosu':
      return import(
        '../data/exercises/by-tools/exercises-bosu.json'
      ) as Promise<ExerciseByToolImport>
    case 'dumbbell':
      return import(
        '../data/exercises/by-tools/exercises-dumbbell.json'
      ) as Promise<ExerciseByToolImport>
    case 'foamroller':
      return import(
        '../data/exercises/by-tools/exercises-foamroller.json'
      ) as Promise<ExerciseByToolImport>
    case 'kettlebell':
      return import(
        '../data/exercises/by-tools/exercises-kettlebell.json'
      ) as Promise<ExerciseByToolImport>
    case 'medicineball':
      return import(
        '../data/exercises/by-tools/exercises-medicineball.json'
      ) as Promise<ExerciseByToolImport>
    case 'pullupbar':
      return import(
        '../data/exercises/by-tools/exercises-pullupbar.json'
      ) as Promise<ExerciseByToolImport>
    case 'resistanceband':
      return import(
        '../data/exercises/by-tools/exercises-resistanceband.json'
      ) as Promise<ExerciseByToolImport>
    case 'swissball':
      return import(
        '../data/exercises/by-tools/exercises-swissball.json'
      ) as Promise<ExerciseByToolImport>
    case 'trx':
      return import(
        '../data/exercises/by-tools/exercises-trx.json'
      ) as Promise<ExerciseByToolImport>
    case 'yoga':
      return import(
        '../data/exercises/by-tools/exercises-yoga.json'
      ) as Promise<ExerciseByToolImport>
    case 'bodyweight':
    default:
      return import(
        '../data/exercises/by-tools/exercises-bodyweight.json'
      ) as Promise<ExerciseByToolImport>
  }
}

export const loadExercisesByTool = async (
  tool: string
): Promise<FitifyExerciseData[]> => {
  const data = await loadExercisesToolsData(tool)
  return data.default.exercises
}

export * from './utils/get-tool-code-from-shortcut'
