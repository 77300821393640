import { WithId } from 'fitify-types/src/types/common'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'
import {
  addDays,
  differenceInCalendarDays,
  getDateFromDateString,
} from 'fitify-utils/src/date'
import { DateTime } from 'luxon'

import { DateFormat } from '@/utils/date'

import { type ActivityCalendarState } from './calendarSlice'

export const removePendingRequest = (
  pendingDaysRequests: Record<string, string[]>,
  requestId: string
) => {
  return Object.entries(pendingDaysRequests).reduce<
    Record<string, WithId<HumanCoaching.Day>['id'][]>
  >((acc, [key, days]) => {
    if (key !== requestId) {
      acc[key] = days
    }
    return acc
  }, {})
}

export const getTargetDaysWithOffset = (
  targetDayId: string,
  sourceDayIds: string[]
): string[] => {
  if (sourceDayIds.length > 0) {
    const firstDayId = sourceDayIds.sort((a, b) => a.localeCompare(b))[0]
    const offset = differenceInCalendarDays(
      getDateFromDateString(targetDayId),
      getDateFromDateString(firstDayId)
    )
    return sourceDayIds.map((sourceDayId) => {
      const targetDayWithOffsetDate = addDays(
        getDateFromDateString(sourceDayId),
        offset
      )
      return DateTime.fromJSDate(targetDayWithOffsetDate).toFormat(
        DateFormat.IsoDate
      )
    })
  }

  return []
}

export const resetCopiedAndSelectedState = (state: ActivityCalendarState) => {
  state.copiedDays = []
  state.copiedActivities = []
  state.selectedDays = []
  state.selectedActivities = []
}
