import * as amplitude from '@amplitude/analytics-browser'
import { type BrowserOptions } from '@amplitude/analytics-types'
import { useEffect, useRef } from 'react'

export const useAmplitude = (
  amplitudeApiKey?: string,
  options?: BrowserOptions
): null => {
  const isAmplitudeLoaded = useRef(false)

  useEffect(() => {
    if (amplitudeApiKey && isAmplitudeLoaded.current === false) {
      amplitude.init(amplitudeApiKey, undefined, {
        defaultTracking: {
          pageViews: true,
          formInteractions: true,
          sessions: true,
          ...options,
        },
      })

      isAmplitudeLoaded.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amplitudeApiKey])

  return null
}
