import Link from 'next/link'

import {
  ButtonStyles,
  ContentWrapper,
  IconWrapper,
  TextWrapper,
} from '@/components/core/Button/Button.Styles'
import { ButtonTypes } from '@/components/core/Button/Button.Types'
import { ButtonLoader } from '@/components/core/Button/ButtonLoader'
import { Text } from '@/components/core/Typography/Text'

const Button = ({
  children,
  href,
  icon,
  iconPosition = 'left',
  loading,
  variant = 'primary',
  size = 'md',
  as = href ? 'a' : 'button',
  disabled,
  fullWidth = false,
  ...rest
}: ButtonTypes.Props) => {
  return href ? (
    <Link href={href}>{children}</Link>
  ) : (
    <ButtonStyles
      {...{ fullWidth, size, variant, as }}
      {...rest}
      disabled={disabled}
    >
      {loading ? (
        <ButtonLoader size={size} />
      ) : (
        <ContentWrapper iconPosition={iconPosition}>
          {icon && <IconWrapper size={size}>{icon}</IconWrapper>}
          <TextWrapper>
            <Text as="span" size={size === 'md' ? 14 : 12} variant="button">
              {children}
            </Text>
          </TextWrapper>
        </ContentWrapper>
      )}
    </ButtonStyles>
  )
}

export default Button
