import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'

import {
  deleteActivities,
  deleteDays,
  duplicateActivities,
  duplicateDays,
  moveActivities,
  moveDays,
  resetCopiedAndSelected,
  setCopiedActivities,
  setCopiedActivitiesFromLocalStorage,
  setCopiedDays,
  setCopiedDaysFromLocalStorage,
} from '@/store/activity/calendar/calendarSlice'

export type CustomLocalStorageListeners =
  | 'persist:copyActivities'
  | 'persist:copyDays'

export const listenerVariants = {
  copyActivities: 'persist:copyActivities',
  copyDays: 'persist:copyDays',
} satisfies Record<string, CustomLocalStorageListeners>

const localStorageMiddleware = createListenerMiddleware()
localStorageMiddleware.startListening({
  actionCreator: setCopiedActivities,
  effect: (action) => {
    localStorage.setItem(listenerVariants.copyDays, '[]')
    localStorage.setItem(
      listenerVariants.copyActivities,
      JSON.stringify(setCopiedActivitiesFromLocalStorage(action.payload))
    )
  },
})
localStorageMiddleware.startListening({
  actionCreator: setCopiedDays,
  effect: (action) => {
    localStorage.setItem(listenerVariants.copyActivities, '[]')
    localStorage.setItem(
      listenerVariants.copyDays,
      JSON.stringify(setCopiedDaysFromLocalStorage(action.payload))
    )
  },
})
localStorageMiddleware.startListening({
  matcher: isAnyOf(
    deleteActivities.pending,
    duplicateActivities.pending,
    moveActivities.pending,
    deleteDays.pending,
    duplicateDays.pending,
    moveDays.pending,
    resetCopiedAndSelected
  ),
  effect: () => {
    localStorage.setItem(listenerVariants.copyActivities, '[]')
    localStorage.setItem(listenerVariants.copyDays, '[]')
  },
})

export const activityCalendarLocalStorageMiddleware =
  localStorageMiddleware.middleware
