import axios, {
  type GenericAbortSignal,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios'
import { getAuth, getIdToken } from 'firebase/auth'
import { toBoolean } from 'fitify-utils/src/properties'

export const API_URL = toBoolean(process.env.PREVIEW)
  ? `/api`
  : process.env.NEXT_PUBLIC_API_URL

const axiosClient = axios.create({
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosClient.interceptors.request.use(
  async (config) => {
    const auth = getAuth()

    if (auth.currentUser) {
      const token = await getIdToken(auth.currentUser)
      config.headers['Authorization'] = token
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

type RequestMethod = AxiosRequestConfig['method']

const performRequest = async (
  method: RequestMethod,
  url: string,
  data?: object,
  headers?: object,
  signal?: GenericAbortSignal
): Promise<AxiosResponse> => {
  const requestData =
    method === 'GET'
      ? {
          params: data,
        }
      : {
          data,
        }
  const response = await axiosClient({
    url,
    method,
    headers,
    signal,
    ...requestData,
  })

  return response
}

export const apiClient = {
  get(
    endpoint: string,
    params?: object,
    signal?: GenericAbortSignal
  ): Promise<AxiosResponse> {
    return performRequest('GET', endpoint, params, undefined, signal)
  },

  post(
    endpoint: string,
    data?: object,
    headers?: object,
    signal?: GenericAbortSignal
  ): Promise<AxiosResponse> {
    return performRequest('POST', endpoint, data, headers, signal)
  },

  patch(
    endpoint: string,
    data?: object,
    signal?: GenericAbortSignal
  ): Promise<AxiosResponse> {
    return performRequest('PATCH', endpoint, data, undefined, signal)
  },

  put(
    endpoint: string,
    data?: object,
    signal?: GenericAbortSignal
  ): Promise<AxiosResponse> {
    return performRequest('PUT', endpoint, data, undefined, signal)
  },

  del(
    endpoint: string,
    data?: object,
    signal?: GenericAbortSignal
  ): Promise<AxiosResponse> {
    return performRequest('DELETE', endpoint, data, undefined, signal)
  },
}
